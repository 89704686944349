<template>
  <div class="container">
    <div class="customer-list">
      <div class="customer-list-contract">
        <div class="title">
          <h4>Danh sách hợp đồng</h4>
        </div>
        <div class="table-responsive overflow-auto">
          <table class="table table-vertical-center table-hover">
            <thead>
              <tr>
                <th>STT</th>
                <th>Mã hợp đồng</th>
                <th>Trung tâm học</th>
                <th>Chi nhánh học</th>
                <th>Số tiền phải đóng</th>
                <th>Đã trả</th>
                <th>Công nợ</th>
                <th>Hình thức thanh toán học phí</th>
                <th>Thời gian tạo</th>
                <th>Điều khoản</th>
              </tr>
            </thead>
            <tbody v-if="contracts.length > 0">
              <tr v-for="(item, key) in contracts">
                <td>{{ ++key }}</td>
                <td>
                  <a href="javascript:void(0)" @click="viewContract(item.id)">{{ item.id }}</a>
                </td>
                <td>{{item.center ? item.center.name : ''}}</td>
                <td>
                  {{ item.branch && item.branch.name }}
                </td>
                <td>{{ formatPrice(item.total_after_voucher, ' đ') }}</td>
                <td>{{ formatPrice(item.total_paid, ' đ') }}</td>
                <td>{{ formatPrice(item.total_debt, ' đ') }}</td>
                <td>{{ tuitionPaymentList[item.tuition_payment_id - 1] }}</td>
                <td>{{ item.created_at | formatDateTimeVietnam }}</td>
                <td>
                  <a title="Xem chi tiết" v-b-modal.modal-policy-contract @click="showPolicy(item.id)" class="btn btn-sm btn-icon mr-2 btn-outline-success">
                    <i class="far fa-eye"></i>
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7" class="event-title no-data text-center">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="customer-list-payment">
        <div class="title">
          <h4>Lịch sử đóng tiền</h4>
        </div>
        <div class="table-responsive overflow-auto">
          <table class="table table-vertical-center table-hover">
            <thead>
            <tr>
              <th>STT</th>
              <th>Mã hóa đơn</th>
              <th>Mã hợp đồng</th>
              <th>Phân loại</th>
              <th>Số tiền nạp</th>
              <th>Bên thu tiền</th>
              <th>Chi nhánh nạp</th>
              <th>Thời gian nạp</th>
              <th>Trạng thái</th>
            </tr>
            </thead>
            <tbody v-if="studentAmounts.length > 0">
            <tr v-for="(item, key) in studentAmounts">
              <td>{{ ++key }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.contract_id }}</td>
              <td v-if="item.type !== 6">
                <ModalShowReason
                    v-if="item.amount < 0 && item.is_refund !== 1"
                    :item="item"
                    :index="key"
                ></ModalShowReason>
                <span v-else-if="item.amount > 0" class="badge badge-pill badge-primary">Nạp tiền</span>
                <span v-else class="badge badge-pill badge-warning cursor-pointer">Hoàn tiền</span>
              </td>
              <td v-else>
                <span class="badge badge-info">Chuyển nhượng</span>
              </td>
              <td>{{ formatPrice(item.amount, ' đ') }}</td>
              <td>{{ item.ben_thu_tien === 0 ? "Trung Tâm" : 'Edutalk' }}</td>
              <td>{{ item.branch && item.branch.name }}</td>
              <td>{{ item.dated_paid | formatDateTimeVietnam }}</td>
              <td><span class="badge" :class="item.status === 1 ? 'badge-success' : 'badge-danger'">{{item.status === 1 ? 'Tồn tại' : 'Đã hủy' }}</span></td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="8" class="event-title no-data text-center">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="customer-list-payment">
        <div class="title">
          <h4>Danh sách quà tặng</h4>
        </div>
        <div class="table-responsive overflow-auto">
          <table class="table table-vertical-center table-hover text-center">
            <thead>
            <tr>
              <th>STT</th>
              <th>Mã quà tặng</th>
              <th>Quà tặng hiện vật</th>
              <th>Quà tặng tiền mặt</th>
              <th>Lý do tặng quà</th>
              <th>Thời gian tặng</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item , key) in refunds">
              <td>{{ ++key }}</td>
              <td>{{item.id}}</td>
              <td>{{item.gift}}</td>
              <td>{{item.value |formatPrice}}</td>
              <td>{{item.note}}</td>
              <td>{{item.created_at |formatDateTimeVietnam}}</td>
            </tr>
            </tbody>
            <tbody>
            <tr>
              <td colspan="8" class="event-title no-data text-center">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div class="customer-list-payment">
        <div class="title">
          <h4>Tài chính theo khóa học</h4>
        </div>
        <div class="table-responsive overflow-auto">
          <table class="table table-vertical-center table-hover">
            <thead>
            <tr>
              <th>STT</th>
              <th>Mã hợp đồng</th>
              <th>Khóa học</th>
              <th>Lớp học</th>
              <th>Chi nhánh học</th>
              <th>Số tiền phải đóng</th>
              <th>Đã trả</th>
              <th>Công nợ</th>
            </tr>
            </thead>
            <tbody v-if="studentRegisterCourses.length > 0">
            <tr v-for="(item, key) in studentRegisterCourses" v-if="item.contract">
              <td>{{ ++key }}</td>
              <td>{{ item.contract_id }}</td>
              <td>{{ item.course && item.course.name }}</td>
              <td>{{ item.center_class && item.center_class.name }}</td>
              <td>{{ item.branch && item.branch.name }}</td>
              <td>{{ formatPrice((item.fee - item.promotion_fee), ' đ') }}</td>
              <td>{{ formatPrice(item.paid, ' đ') }}</td>
              <td>{{ formatPrice(item.fee - item.promotion_fee - item.paid, ' đ') }}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="8" class="event-title no-data text-center">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div class="customer-list-payment">
        <div class="title">
          <h4>Lịch sử chuyển nhượng</h4>
        </div>
        <div class="table-responsive overflow-auto">
          <table class="table table-vertical-center table-hover">
            <thead>
            <tr>
              <th>STT</th>
              <th>Người chuyển</th>
              <th>Người nhận</th>
              <th>Khóa học chuyển nhượng</th>
              <th>Thời gian chuyển nhượng</th>
            </tr>
            </thead>
            <tbody v-if="historyTransfer.length > 0" >
            <tr v-for="(item, key) in historyTransfer">
              <td>{{++key}}</td>
              <td>{{item && item.from_user && item.from_user.phone}} - {{item && item.from_user && item.from_user.name}}</td>
              <td>{{item && item.from_user && item.to_user.phone}} - {{item && item.from_user && item.to_user.name}}</td>
              <td>
                <div>
                     <span class="ml-1 badge badge-pill badge-success mt-1"
                        v-if="item.courses.length > 0" v-for="(course, k) in item.courses" :key="k">
                        {{course.name}}
                    </span>
                </div>
              </td>
              <td>{{item && item.created_at | formatDateTimeVietnam }}
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="8" class = "event-title no-data text-center">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
    <ContractDetail :showModal="showDetailContract" :contract="contract"
                    @clicked="onclickViewContractDetail"></ContractDetail>
    <ModalPolicyContract :policy="policy"></ModalPolicyContract>
  </div>
</template>

<script>
import {GET_CONTRACT_BY_ID, GET_POLICT_CONTRACT} from "@/core/services/store/contract/contracts.module";
import ContractDetail from "@/view/pages/business/ContractDetail";
import ModalShowReason from "../../contracts/ModalShowReason";
import ModalPolicyContract from "@/view/pages/customers/Modal/ModalPolicyContract";
export default {
  name: "Tai-Chinh",
  props: {
    contracts: {
      type: Array,
      default: () => { return [] }
    },
    studentAmounts: {
      type: Array,
      default: () => { return [] }
    },
    historyTransfer: {
      type: Array,
      default: () => { return []}
    },
    studentRegisterCourses: {
      type: Array,
      default: () => { return [] }
    },
    refunds: {
      type: Array,
      default: () => { return [] }
    },
    totalAfterVoucher: 0,
    totalPaid: 0,
    totalDebt: 0,
    totalWithdraw: 0,
    cash: 0,
  },
  data() {
    return {
      showDetailContract: false,
      contract: {},
      policy: {},
      tuitionPaymentList: [
          'Hoàn thành toàn bộ',
          'Trả góp ngân hàng',
          'Trả góp trung tâm',
          'Đóng theo giai đoạn',
          'Trả góp Citime'
      ]
    }
  },

  components: {
    ContractDetail,
    ModalShowReason,
    ModalPolicyContract
  },
  methods: {
    viewContract: async function (contract_id) {
      await this.getContractDetail(contract_id);
      this.showDetailContract = true;
    },
    getContractDetail: async function (contract_id){
      await this.$store.dispatch(GET_CONTRACT_BY_ID, contract_id).then((res) => {
        this.contract = res.data;
      });
    },
    onclickViewContractDetail() {
      this.showDetailContract = false;
    },
    showPolicy(id) {
      this.$store.dispatch(GET_POLICT_CONTRACT, id).then((res) => {
        _.sortBy(res.data, 'index');
        this.policy = res.data;
      });
    }
  }
}
</script>

<style scoped>
table{
  border: 1px solid #e3e4ed;
}
</style>
